
import { defineComponent, ref } from "vue";
import { ErrorMessage, Field, Form } from "vee-validate";

export default defineComponent({
  name: "CoustomForm",
  components: {
    ErrorMessage,
    Field,
    Form,
  },
  setup() {
    const form = ref({
      partnerName: '',
      alternativeName: '',
      natureOfBusiness: '',
      groupType: '',
      industryType: '',
      distributer: '',
      businessType: '',
      pan: '',
      dateOfIncorporation: '',
      cin: '',
      registrationNo: '',
      roc: '',
      website: '',
      offer: '',
      employeePF: false
    });
    return {
      form
    };
  }
   
});
